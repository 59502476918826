import React from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import { css } from '@emotion/core';

import { Container } from '@myungsoo/components';

const News = ({
  config: { DB_ROOT_PATH },
  onLoadingChange = () => {},
  ...restProps
}) => {
  const [page, setPage] = React.useState({ content: '' });
  const [articles, setArticles] = React.useState([]);

  const loadPage = React.useCallback(async () => {
    onLoadingChange(true);
    try {
      const firebase = await import('firebase/app');
      const db = firebase.firestore();
      const pageDoc = await db.doc(`${DB_ROOT_PATH}/pages/news`).get();

      if (!pageDoc.exists) {
        return;
      }

      setPage(pageDoc.data());
    } finally {
      onLoadingChange(false);
    }
  }, [DB_ROOT_PATH, onLoadingChange]);

  const loadArticles = React.useCallback(async () => {
    onLoadingChange(true);
    try {
      const firebase = await import('firebase/app');
      const db = firebase.firestore();
      const pageRef = db.doc(`${DB_ROOT_PATH}/pages/news`);
      const articlesRef = pageRef.collection('articles');

      const articleCollection = await articlesRef
        .orderBy('createdAt', 'desc')
        .get();

      const loadedArticles = fp.flow(
        fp.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
        fp.orderBy('displayOrder', 'asc'),
      )(articleCollection.docs);

      setArticles(loadedArticles);
    } finally {
      onLoadingChange(false);
    }
  }, [DB_ROOT_PATH, onLoadingChange]);

  React.useEffect(() => {
    loadPage();
    loadArticles();
  }, [loadPage, loadArticles]);

  React.useEffect(() => {
    const id = _.trim(window.location.hash, '#');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView(true);
    }
  }, [articles]);

  return (
    <Container {...restProps}>
      <div dangerouslySetInnerHTML={{ __html: page.content }} />

      {_.map(articles, (article, index) => {
        return (
          <React.Fragment key={article.id}>
            <a
              id={article.id}
              css={css`
                display: inline-block;
                margin-top: -60px;
                margin-bottom: 60px;
              `}
            />
            <article
              css={css`
                padding: 0;
              `}
            >
              <h1>
                <a href={`#${article.id}`}>{article.title}</a>
              </h1>
              <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </article>
            {index < _.size(articles) - 1 && (
              <hr
                css={css`
                  margin: 2rem 0;
                `}
              />
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default News;
