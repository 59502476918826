import React from 'react';

import News from '@myungsoo/base/pages/News';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import * as config from '../../config';

const NewsPage = () => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Layout loading={loading}>
      <Seo title="News" />
      <News config={config} onLoadingChange={setLoading} />
    </Layout>
  );
};

export default NewsPage;
